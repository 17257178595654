import {
  Badge,
  Button,
  Icon,
  SocialMediaIcon,
  SocialMediaIconProps,
} from '@kontentino/ui';
import React, { FC } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import PageTypeUtils from 'app/utils/pageType';

type Props = {
  type: number;
  onClick: () => void;
  dataName?: string;
};

const SOCIAL_MEDIA_TYPES = [
  { type: TYPE.FACEBOOK, description: 'Business Page' },
  { type: TYPE.INSTAGRAM, description: 'Business or Creator' },
  { type: TYPE.TIKTOK, description: 'Business or Creator', new: true },
  { type: TYPE.LINKEDIN, description: 'Page or Profile' },
  { type: TYPE.TWITTER, description: 'Profile' },
  { type: TYPE.PINTEREST, description: 'Business or Profile' },
  { type: TYPE.GOOGLE_MY_BUSINESS, description: 'Page' },
  { type: TYPE.THREADS, description: 'Profile' },
];

const SOCIAL_MEDIA_TYPE_OPTION_CONFIG = SOCIAL_MEDIA_TYPES.reduce(
  (config, { type, description, ...rest }) => {
    config[type] = {
      key: PageTypeUtils.getName(type),
      title: PageTypeUtils.getLabel(type),
      description,
      ...rest,
    };
    return config;
  },
  {} as {
    [key: number]: {
      key: string;
      title: string;
      description: string;
      comingSoon?: boolean;
      new?: boolean;
    };
  },
);

const SelectSocialTypeOption: FC<Props> = ({ type, onClick, dataName }) => {
  const { t } = useTranslation();
  const socialMedia = SOCIAL_MEDIA_TYPE_OPTION_CONFIG[type];

  return (
    <div
      tabIndex={0}
      className={clsx(
        'tw-group tw-relative tw-flex tw-h-[142px] tw-w-full tw-select-none tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-rounded-md tw-border tw-border-grayscale-10 tw-bg-transparent tw-text-center tw-transition-all tw-duration-300',
        {
          'tw-cursor-pointer hover:tw-border-primary-100':
            !socialMedia.comingSoon,
        },
      )}
      onClick={socialMedia.comingSoon ? undefined : onClick}
      data-cy={`connect-social-media-profile_${socialMedia.key}`}
      data-name={dataName}
    >
      <SocialMediaIcon
        type={socialMedia.key as SocialMediaIconProps['type']}
        variant="plain"
        size={38}
      />
      <div
        className={clsx('tw-h-[28px]', {
          'group-hover:tw-hidden': !socialMedia.comingSoon,
        })}
      >
        <div className="tw-flex tw-items-center tw-justify-center tw-text-md tw-font-medium tw-text-grayscale-180">
          {socialMedia?.title}
        </div>
        <div className="tw-text-xs tw-text-grayscale-140">
          {socialMedia?.description}
        </div>
      </div>
      {!socialMedia.comingSoon && (
        <Button
          className="tw-hidden group-hover:tw-block"
          variant="tertiary"
          size="small"
        >
          {t('connect')}
        </Button>
      )}
      {socialMedia.comingSoon && (
        <Badge variant="purple" className="tw-absolute tw-right-1 tw-top-1">
          <Icon size="sm" icon={faSparkles} />
          {t('comingSoon')}
        </Badge>
      )}
      {socialMedia.new && (
        <Badge variant="gray" className="tw-absolute tw-right-1 tw-top-1">
          <Icon size="sm" icon={faSparkles} />
          {t('new')}
        </Badge>
      )}
    </div>
  );
};

export default SelectSocialTypeOption;
